import { useFrame } from "@react-three/fiber";
import { useRef } from "react";
import { Text } from "@react-three/drei";
import * as THREE from 'three'

export default function BlinkingText()
{

    const blinkingtextRef = useRef()
    const savingtodatabaseRef = useRef()

    const blinkClock = useRef(new THREE.Clock())
    let isVisible = true;

    useFrame(() => 
    {
        const blinkSpeed = 2

        const elapsedTime = Math.floor(blinkClock.current.getElapsedTime() * 2);
        
        if (elapsedTime % blinkSpeed == 0) {
            blinkingtextRef.current.visible = true;
            savingtodatabaseRef.current.visible = true;
        } else if(elapsedTime % blinkSpeed != 0){
            blinkingtextRef.current.visible = false;
            savingtodatabaseRef.current.visible = false;
            isVisible = false;
        }
    })

    return <>
        <Text
            ref={ blinkingtextRef }
            color={ "black" }
            scale={ 0.17 }
            font="./fonts/WritingBook-1GwXM.otf"
            position={ [ 0.53, 0.89, - 48.1 ] }
        >
            Uploading Encrypted Data...
        </ Text>

        <Text
            ref={ savingtodatabaseRef }
            color={ "white" }
            scale={ 0.13 }
            font="./fonts/WritingBook-1GwXM.otf"
            position={ [ 1.7, 0.02, - 48.1 ] }
        >
            Saving to Database ...
        </Text>
    </>
}