import { KeyboardControls, OrbitControls, PointerLockControls, Sky } from '@react-three/drei'
import { Debug, Physics } from '@react-three/rapier'
import { Perf } from 'r3f-perf'

import { Player } from './Player.jsx'
import Floor from './floor/Floor.jsx'
import MuseumMats from './museum/MuseumMats.jsx'
import Lights from './Lights.jsx'

export default function Experience()
{
    return <>
      {/* <Perf position="top-left" /> */}

      <Sky sunPosition={[100, 20, 100]} />

      <Lights />

      <Physics gravity={[0, -30, 0]} >
        {/* <Debug /> */}
          <Floor />
          <Player />

          <MuseumMats />
      </Physics>
      <PointerLockControls />
    </>
}