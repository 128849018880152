import { useGLTF } from "@react-three/drei"
import { RigidBody } from "@react-three/rapier"

export default function MyFrame()
{
    const billboard = useGLTF('./Museum/Blended/Frame.glb')

    billboard.scene.children.forEach((mesh) => 
    {
        mesh.castShadow = true
    })

    return <>
        <RigidBody type="fixed">
            <primitive 
                castShadow
                object={ billboard.scene }
                scale={ 0.25 }
                rotation-y={ Math.PI * 1 }
                rotation-x={ Math.PI * 0.5 }
                position={ [ - 2.8, 1.4, - 9.88 ] }
            />
        </RigidBody>
    </>
}

useGLTF.preload('./Museum/Blended/Frame.glb')