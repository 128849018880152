import * as THREE from 'three'
import { useGLTF, Clone, Text, Float, useMatcapTexture, useTexture, Html, PresentationControls, BakeShadows} from '@react-three/drei'
import { RigidBody, CuboidCollider, BallCollider } from '@react-three/rapier'
import { useEffect, useRef } from 'react'
import Aderski from '../assets/Aderski.jsx'

export default function Hris()
{
    // const [ matcapTexture ] = useMatcapTexture('17395A_7EBCC7_4D8B9F_65A1B5', 256)

    // const material = new THREE.MeshMatcapMaterial()

    const laptopRef = useRef()

    // useEffect(() => 
    // {
    //     matcapTexture.colorSpace = THREE.SRGBColorSpace
    //     matcapTexture.needsUpdate = true

    //     material.matcap = matcapTexture
    //     material.needsUpdate = true
    // })

    const laptop = useGLTF('./Museum/Blended/JieeLaptop.glb')

    const RightLargeStone = useGLTF('./Museum/Blended/RightLargeStone.glb')

    const LeftLargeStone = useGLTF('./Museum/Blended/LeftLargeStone.glb')

    const RockForrest = useGLTF('./Museum/Blended/RockForrest.gltf')

    const Palmshort = useGLTF('./Museum/Blended/PalmShort.gltf')

    const Cauliflower = useGLTF('./Museum/Blended/Cauliflower.gltf')

    const Grass = useGLTF('./Museum/Blended/Grass.glb')

    const TargetStand = useGLTF('./Museum/Blended/TargetStand.gltf')
    
    const Quiver = useGLTF('./Museum/Blended/Quiver.gltf')

    const Compass = useGLTF('./Museum/Blended/Compass.glb')

    const Monitor = useGLTF('./Museum/Blended/Monitor.glb')

    Monitor.scene.children.forEach((mesh) =>
    {
        mesh.castShadow = true
    })

    RockForrest.scene.children.forEach((mesh) =>
    {
        mesh.castShadow = true
    })

    Grass.scene.children.forEach((mesh) =>
    {
        mesh.castShadow = true
    })

    return <>

    {/** Text */ }
        <group position={[ 0.13, - 2.5, 0.6 ]} >
            <Float floatIntensity={ 0.02 } rotationIntensity={ 0.02 }>
                <Text 
                    color={'skyblue'}
                    font="./fonts/WritingBook-1GwXM.otf"
                    scale={ 0.3 }
                    maxWidth={ 4.4 }
                    position={ [ - 0.8, 3, - 35.5 ] }
                >
                    GEO LOCATION 
                </Text>

                <Text 
                    color={'skyblue'}
                    font="./fonts/WritingBook-1GwXM.otf"
                    scale={ 0.3 }
                    maxWidth={ 4.4 }
                    position={ [ - 0.6, 2.7, - 35.5 ] }
                >
                    BENEFITS
                </Text>

                <Text 
                    color={'skyblue'}
                    font="./fonts/WritingBook-1GwXM.otf"
                    scale={ 0.5 }
                    maxWidth={ 4.4 }
                    position={ [ - 0.4, 3.37, - 35.5 ] }
                >
                    ALPHALIST
                </Text>

                <Text 
                    color={'skyblue'}
                    font="./fonts/WritingBook-1GwXM.otf"
                    scale={ 0.3 }
                    maxWidth={ 4.4 }
                    position={ [ 0.7, 3.32, - 35.5 ] }
                >
                    RECRUITMENT
                </Text>

                <Text 
                    color={'skyblue'}
                    font="./fonts/WritingBook-1GwXM.otf"
                    scale={ 0.3 }
                    maxWidth={ 4.4 }
                    position={ [ 1.7, 3.32, - 35.5 ] }
                >
                    MASTERLIST
                </Text>

                <Text 
                    color={'skyblue'}
                    font="./fonts/WritingBook-1GwXM.otf"
                    scale={ 0.5 }
                    maxWidth={ 4.4 }
                    position={ [ 2.5, 3.36, - 35.5 ] }
                >
                    2316
                </Text>

                <Text 
                    color={'skyblue'}
                    font="./fonts/WritingBook-1GwXM.otf"
                    scale={ 0.5 }
                    maxWidth={ 4.4 }
                    position={ [ - 0.3, 3.8, - 35.5 ] }
                >
                    REPORTS
                </Text>

                <Text 
                    color={'skyblue'}
                    font="./fonts/WritingBook-1GwXM.otf"
                    scale={ 0.5 }
                    maxWidth={ 4.4 }
                    position={ [ 1.06, 3.66, - 35.5 ] }
                >
                    LOAN HISTORY
                </Text>

                <Text 
                    color={'skyblue'}
                    font="./fonts/WritingBook-1GwXM.otf"
                    scale={ 0.4 }
                    maxWidth={ 4.4 }
                    position={ [ 2.33, 3.73, - 35.5 ] }
                >
                    BIR FORM
                </Text>

                <Text 
                    color={'skyblue'}
                    font="./fonts/WritingBook-1GwXM.otf"
                    scale={ 0.6 }
                    maxWidth={ 6 }
                    position={ [ 1.3, 2.9, - 35.5 ] }
                >
                    EMPLOYEE INTERFACE
                </Text>
            </Float>
        </group>

        {/** Laptop */}
        <primitive 
            ref={ laptopRef }
            object={ laptop.scene }
            scale={ 0.97 }
            position={ [ 1, 0.63, - 35 ] }

        >
            {/* <Html
                transform
                wrapperClass='htmlScreen'
                // distanceFactor={ 1.17 }
                scale={ 0.4 }
                position={ [0, 1.56, -1.4] }
                rotation-x={ -0.256 }
                occlude
            >
                <iframe 
                    src="./img/HRIS.PNG"
                />
            </Html> */}
        </primitive>

        {/** Aderski */}

        <Aderski 
            position={ 
            [
                [ - 1.4, - 1, - 27.9 ], 
                [ - 2.7, - 1, - 28.9 ],
                [ - 3.1, - 1, - 30.9 ],
                [ 3.5, - 1, - 27.9 ],
                [ 4.65, - 1, - 28.9 ],
                [ 5, - 1, - 30.9 ]
            ] 
            }/>

        {/** Ball Collider */}
        
        <BallCollider 
            args={[ 5 ]} 
            position={ [ 1, - 1.65, - 32 ] }
            restitution={ 0.1 } 
            friction={ 0.1 }
        />

        <primitive 
            object={ Compass.scene }
            scale={ 0.3 }
            rotation-x={ Math.PI * 0.4 }
            rotation-z={ - Math.PI * 0.2 }
            position={ [ 4.4, 0.3, - 34.3 ] }
        />

        {/** Right Stone */}
        <RigidBody type='fixed' colliders="hull">
            <primitive
                receiveShadow
                object={ RightLargeStone.scene }
                scale={ 1.5 }
                position={ [ 4.5, - 1, - 36.9 ] }
            />
        
        {/** Left Stone */}
        <primitive
            receiveShadow
            object={ LeftLargeStone.scene }
            scale={ 1.5 }
            position={ [ - 3.68, - 0.9, - 35 ] }
        />
        </RigidBody>

        {/** Rocks */}
        <primitive
            object={ RockForrest.scene }
            scale={ 0.5 }
            position={ [ - 2.68, - 1, - 32 ] }
        />

        <Clone
            object={ RockForrest.scene }
            scale={ 0.5 }
            rotation-y={ Math.PI * 0.9 }
            position={ [ - 1, - 1, - 29.5 ] }
        />

        <Clone
            object={ RockForrest.scene }
            scale={ 0.5 }
            rotation-y={ - Math.PI * 0.4 }
            position={ [ 3, - 1, - 29.5 ] }
        />

        <Clone
            object={ RockForrest.scene }
            scale={ 0.5 }
            rotation-y={ Math.PI * 0.4 }
            position={ [ 3.98, - 1, - 32 ] }
        />

        {/** Palm Tree */}

        <primitive
            receiveShadow
            object={ Palmshort.scene }
            scale={ 1.5 }
            position={ [ - 8.5, - 1.02, - 23 ] }
        />

        {/** Flowers & Grass */}

        {/* <primitive
            object={ Grass.scene }
            scale={ 0.2 }
            position={ [ - 2.1, - 1.06, - 32.4 ] }
        />

        <Clone
            object={ Grass.scene }
            scale={ 0.2 }
            rotation-y={ Math.PI * 0.5 }
            position={ [ - 2.23, - 1.06, - 32.4 ] }
        />

        <Clone
            object={ Grass.scene }
            scale={ 0.2 }
            rotation-y={ Math.PI * 0.5 }
            position={ [ - 1.94, - 1.06, - 32.4 ] }
        />
        <Clone
            object={ Grass.scene }
            scale={ 0.2 }
            rotation-y={ Math.PI * 0.8 }
            position={ [ - 1.8, - 1.06, - 32.4 ] }
        />

        <Clone
            object={ Grass.scene }
            scale={ 0.2 }
            rotation-y={ Math.PI * 0.2 }
            position={ [ - 1.68, - 1.06, - 32.4 ] }
        />

        <primitive
            object={ Cauliflower.scene }
            scale={ 0.2 }
            position={ [ - 1.68, - 1, - 30.4 ] }
        />

        <Clone
            object={ Cauliflower.scene }
            scale={ 0.2 }
            position={ [ - 2, - 1, - 31.4 ] }
        />

        <Clone
            object={ Cauliflower.scene }
            scale={ 0.2 }
            position={ [ - 2.1, - 1, - 30.8 ] }
        />

        <Clone
            object={ Cauliflower.scene }
            scale={ 0.2 }
            position={ [ - 2.5, - 1, - 31.8 ] }
        />

        <Clone
            object={ Cauliflower.scene }
            scale={ 0.2 }
            position={ [ - 1.4, - 1, - 30.8 ] }
        /> */}

        <primitive
            object={ TargetStand.scene }
            scale={ 0.5 }
            position={ [ 4, - 1, - 33.4 ] }
        />
    
        <primitive
            object={ Quiver.scene }
            scale={ 0.6 }
            position={ [ 4.5, - 0.8, - 33.2 ] }
        />

        {/** Monitor */}
        
        <RigidBody 
            type='fixed' 
            colliders="cuboid" 
            restitution={ 0.1 }
            friction={ 0.1 }
        >
            <primitive 
                object={ Monitor.scene }
                scale={ 0.5 }
                position={ [ - 1.1, - 0.51, - 27.2 ] }
            />
        </RigidBody>

        {/** Description */}

        <Text
            font="./fonts/MinionPro-Semibold.otf"
            scale={ 0.08 }
            maxWidth={ 24 }
            color={ "black" }
            position={ [ - 1.1, - 0.13, - 27.15 ] }
        >
            HRIS
        </Text>

        <Text
            font="./fonts/Lato-Thin.ttf"
            scale={ 0.06 }
            maxWidth={ 8.5 }
            color={ "black" }
            textAlign='center'
            position={ [ - 1.1, - 0.5, - 27.15 ] }
        >
            Payroll System with additional features of real-time time logs and applications for leaves, overtime, travel etc.
        </Text>
    </>
}

useGLTF.preload('./Museum/Blended/JieeLaptop.glb')
useGLTF.preload('./Museum/Blended/RightLargeStone.glb')
useGLTF.preload('./Museum/Blended/LeftLargeStone.glb')
useGLTF.preload('./Museum/Blended/RockForrest.gltf')
useGLTF.preload('./Museum/Blended/PalmShort.gltf')
useGLTF.preload('./Museum/Blended/Cauliflower.gltf')
useGLTF.preload('./Museum/Blended/Grass.glb')
useGLTF.preload('./Museum/Blended/TargetStand.gltf')
useGLTF.preload('./Museum/Blended/Quiver.gltf')
useGLTF.preload('./Museum/Blended/Compass.glb')
useGLTF.preload('./Museum/Blended/Monitor.glb')