import { CuboidCollider, RigidBody } from '@react-three/rapier'
import * as THREE from 'three'
import { Text, SpotLight, Box } from '@react-three/drei'
import { useEffect, useRef, useState } from 'react'
import Dinosaur from '../assets/Dinosaur.jsx'
import { useFrame } from '@react-three/fiber'
import FrameSpotLight from './FrameSpotLight.jsx'

const boxGeometry = new THREE.BoxGeometry(1, 1, 1)
const floor = new THREE.MeshStandardMaterial({color: '#ffffff'})

const wallGeometry = new THREE.BoxGeometry(1, 1, 1)
const wall = new THREE.MeshStandardMaterial({ color: '#ffffff'})

const backwallGeometry = new THREE.BoxGeometry(2.1, 0.05, 1.05)
const backwall = new THREE.MeshStandardMaterial({ color: '#C9DFEC'})

const innerWall = new THREE.MeshStandardMaterial()

const backWall = new THREE.MeshStandardMaterial()

const flooringTexture = new THREE.MeshStandardMaterial()

{ /* Texture */ }

const loadingManager = new THREE.LoadingManager()

const textureLoader = new THREE.TextureLoader(loadingManager)

{ /** WALLS */ }
const alphaTexture = textureLoader.load('./maps/ArtDeco002/Wallpaper_ArtDeco_002_basecolor.jpg',)
const heightTexture = textureLoader.load('./maps/ArtDeco002/Wallpaper_ArtDeco_002_height.jpg',)
const normalTexture = textureLoader.load('./maps/ArtDeco002/Wallpaper_ArtDeco_002_normal.jpg',)
const ambientOcclusionTexture = textureLoader.load('./maps/ArtDeco002/Wallpaper_ArtDeco_002_ambientOcclusion.jpg',)
const metalTexture = textureLoader.load('./maps/ArtDeco002/Wallpaper_ArtDeco_002_metallic.jpg',)
const roughnessTexture = textureLoader.load('./maps/ArtDeco002/Wallpaper_ArtDeco_002_roughness.jpg',)

innerWall.metalness = 0.2
innerWall.map = alphaTexture
innerWall.aoMap = ambientOcclusionTexture
innerWall.aoMapIntensity = 1
innerWall.displacementMap = heightTexture
innerWall.displacementScale = 0.1
innerWall.metalnessMap = metalTexture
innerWall.roughnessMap = roughnessTexture
innerWall.normalMap = normalTexture
innerWall.normalScale.set(5, 5)

alphaTexture.mipmaps = false
alphaTexture.wrapS = THREE.RepeatWrapping
alphaTexture.wrapT = THREE.RepeatWrapping

alphaTexture.repeat.set(30, 3)
metalTexture.repeat.set(30, 3)

const backalphaTexture = textureLoader.load('./maps/ArtDeco002/Wallpaper_ArtDeco_002_basecolor.jpg',)
const backmetalTexture = textureLoader.load('./maps/ArtDeco002/Wallpaper_ArtDeco_002_metallic.jpg',)

backWall.metalness = 0.2
backWall.map = backalphaTexture
backWall.aoMap = ambientOcclusionTexture
backWall.aoMapIntensity = 1
backWall.displacementMap = heightTexture
backWall.displacementScale = 0.1
backWall.metalnessMap = backmetalTexture
backWall.roughnessMap = roughnessTexture
backWall.normalMap = normalTexture
backWall.normalScale.set(5, 5)

backalphaTexture.mipmaps = false
backalphaTexture.wrapS = THREE.RepeatWrapping
backalphaTexture.wrapT = THREE.RepeatWrapping

backalphaTexture.repeat.set(7, 3)
backmetalTexture.repeat.set(7, 3)

const alphaFloorTexture = textureLoader.load('./maps/WoodHerringbone/Substance_Graph_BaseColor.jpg',)
const heightFloorTexture = textureLoader.load('./maps/WoodHerringbone/Substance_Graph_Height.jpg',)
const normalFloorTexture = textureLoader.load('./maps/WoodHerringbone/Substance_Graph_Normal.jpg',)
const ambientOcclusionFloorTexture = textureLoader.load('./maps/WoodHerringbone/Substance_Graph_AmbientOcclusion.jpg',)
const roughnessFloorTexture = textureLoader.load('./maps/WoodHerringbone/Substance_Graph_Roughness.jpg',)

flooringTexture.metalness = 0.1
flooringTexture.roughness = 0.1
flooringTexture.map = alphaFloorTexture
flooringTexture.aoMap = ambientOcclusionFloorTexture
flooringTexture.aoMapIntensity = 1
flooringTexture.displacementMap = heightFloorTexture
flooringTexture.displacementScale = 0.1
flooringTexture.roughnessMap = roughnessFloorTexture
flooringTexture.normalMap = normalFloorTexture
flooringTexture.normalScale.set(2, 2)

alphaFloorTexture.mipmaps = false
alphaFloorTexture.wrapS = THREE.RepeatWrapping
alphaFloorTexture.wrapT = THREE.RepeatWrapping

alphaFloorTexture.repeat.set(20, 20);
ambientOcclusionFloorTexture.repeat.set(20, 20)


{/** FLOOR */}

const wallCornerDesign = new THREE.MeshStandardMaterial({ color: '#BCC6CC'})

const planeGeometry = new THREE.PlaneGeometry(20.4, 95)
const planeMaterial = new THREE.MeshPhysicalMaterial({ transparent: true, opacity:0.5, color:'white'})

const starryNighttexture = textureLoader.load('./img/Van-Gogh-Starry-Night-FB.jpg');

starryNighttexture.wrapS = THREE.RepeatWrapping;
starryNighttexture.wrapT = THREE.RepeatWrapping;
starryNighttexture.repeat.set(1, 1);

export default function Floor()
{
    const material = new THREE.MeshMatcapMaterial()
    // const [ matcapTexture ] = useMatcapTexture('28292A_D3DAE5_A3ACB8_818183', 256)

    const [isLoaded, setIsLoaded] = useState(false);

    const matcapId = '28292A_D3DAE5_A3ACB8_818183'
    const size = 256

    useEffect(() => 
    {
        // matcapTexture.colorSpace = THREE.SRGBColorSpace
        // matcapTexture.needsUpdate = true

        // material.receiveShadow = true
        // material.matcap = matcapTexture
        // material.needsUpdate = true

        // textureLoader.load(
        //     'https://raw.githubusercontent.com/nidorx/matcaps/master/256/28292A_D3DAE5_A3ACB8_818183-256px.png',
        //     () => setIsLoaded(true)
        // );

        // console.log(isLoaded);

    })
    
    { /** CLOCK */ }
    const clockRef = useRef(new THREE.Clock())
    const colorRef = useRef()

    const randomColorArr = ['#00A6FF', '#C0FF7D', '#FFC354', '#FF5B6C', '#BB9EFF']
    let randomColor = ''
    let temprandomColor = ''
    let selectedColor = '';


    return <>
        {/** Dinosaur */}

        <Dinosaur />
        
        {/** CEILING */}
        <mesh 
            geometry={ planeGeometry } 
            material={ planeMaterial } 
            position={ [0, 4, - 30] } 
            rotation-x={ Math.PI * 0.5} 
        >
        </mesh>

        <RigidBody type="kinematicPosition" canSleep>

            {/** FLOOR */}
            <mesh 
                receiveShadow
                canSleep={ true } 
                geometry={ boxGeometry }
                material={ flooringTexture } 
                position={ [0, -1.1, - 30] } 
                scale={ [20, 0.2, 95] }
            >
                
            </mesh>


            {/** Front Wall */}
            <mesh 
                castShadow
                receiveShadow
                canSleep={ true } 
                geometry={ wallGeometry }
                material={ wall } 
                position={ [0, 1.5, - 15] } 
                rotation-x={ - Math.PI * 0.5 }
                scale={ [10, 10, 5] }
            >
            </mesh>

            <mesh
                castShadow
                receiveShadow
                canSleep={ true } 
                geometry={ wallGeometry }
                material={ wallCornerDesign } 
                position={ [ 0, - 0.9, - 20.05 ] } 
                rotation-z={ - Math.PI }
                rotation-x={ - Math.PI * 0.5 }
                scale={ [ 10.2, 0.1, 0.2 ] }
            >
            </mesh>

            <mesh
                castShadow
                receiveShadow
                canSleep={ true } 
                geometry={ wallGeometry }
                material={ wallCornerDesign } 
                position={ [ 0, 3.9, - 20.05 ] } 
                rotation-z={ - Math.PI }
                rotation-x={ - Math.PI * 0.5 }
                scale={ [ 10.2, 0.1, 0.2 ] }
            >
            </mesh>

            <mesh
                castShadow
                receiveShadow
                canSleep={ true } 
                geometry={ wallGeometry }
                material={ wallCornerDesign } 
                position={ [ 0, - 0.9, - 9.95 ] } 
                rotation-z={ - Math.PI }
                rotation-x={ - Math.PI * 0.5 }
                scale={ [ 10.2, 0.1, 0.2 ] }
            >
            </mesh>

            <mesh
                castShadow
                receiveShadow
                canSleep={ true } 
                geometry={ wallGeometry }
                material={ wallCornerDesign } 
                position={ [ 0, 3.9, - 9.95 ] } 
                rotation-z={ - Math.PI }
                rotation-x={ - Math.PI * 0.5 }
                scale={ [ 10.2, 0.1, 0.2 ] }
            >
            </mesh>

            {/** Back Wall */}
            <RigidBody type='fixed'>
                <mesh 
                    castShadow
                    receiveShadow
                    canSleep={ true } 
                    geometry={ backwallGeometry }
                    material={ backWall } 
                    position={ [0.1, 1.4, - 77] } 
                    rotation-x={ - Math.PI * 0.5 }
                    scale={ [10, 10, 5] }
                >
                </mesh>

                <mesh
                    castShadow
                    receiveShadow
                    canSleep={ true } 
                    geometry={ wallGeometry }
                    material={ wallCornerDesign } 
                    position={ [0, - 0.9, - 76.7] } 
                    rotation-z={ - Math.PI}
                    rotation-x={ - Math.PI * 0.5 }
                    scale={ [20, 0.1, 0.2] }
                >
                </mesh>
            </RigidBody>
            {/** Entrance Wall */}
            <RigidBody type='fixed'>
                <mesh 
                    castShadow
                    receiveShadow
                    canSleep={ true } 
                    geometry={ backwallGeometry }
                    material={ backWall } 
                    position={ [0.1, 1.4, 9] } 
                    rotation-x={ - Math.PI * 0.5 }
                    scale={ [10, 10, 5] }
                >
                </mesh>

                <mesh
                    castShadow
                    receiveShadow
                    canSleep={ true } 
                    geometry={ wallGeometry }
                    material={ wallCornerDesign } 
                    position={ [0, - 0.9, 8.7] } 
                    rotation-z={ - Math.PI}
                    rotation-x={ - Math.PI * 0.5 }
                    scale={ [20, 0.1, 0.2] }
                >
                </mesh>
            </RigidBody>

            <Text
                font='./fonts/MrDafoe-Regular.ttf'
                scale={ 1 }
                position={ [0, 2.4, - 76.7] }
                color={ "white" }
            >
                " Just Keep Moving Forward. "
            </Text>

            <Text
                font='./fonts/MrDafoe-Regular.ttf'
                scale={ 0.5 }
                position={ [3.5, 1.4, - 76.7] }
                color={ "white" }
            >
                Thank you!
            </Text>

            <Text
                font='./fonts/MrDafoe-Regular.ttf'
                scale={ 0.3 }
                position={ [3.3, 1, - 76.7] }
                color={ "white" }
            >
                - Anjury Patawaran
            </Text>

            

            {/** 2nd Wall */}
            <mesh 
                castShadow
                receiveShadow
                canSleep={ true } 
                geometry={ wallGeometry } 
                position={ [0, 1.5, - 60] } 
                rotation-x={ - Math.PI * 0.5 }
                scale={ [10, 10, 5] }
            >
                <meshBasicMaterial
                    attach="material"
                    map={ starryNighttexture }
                />
            </mesh>

            <mesh
                castShadow
                receiveShadow
                canSleep={ true } 
                geometry={ wallGeometry }
                material={ wallCornerDesign } 
                position={ [5.05, - 0.9, - 15] } 
                rotation-z={ - Math.PI * 0.5 }
                rotation-x={ - Math.PI * 0.5 }
                scale={ [ 10, 0.1, 0.2 ] }
            >
            </mesh>

            <mesh
                castShadow
                receiveShadow
                canSleep={ true } 
                geometry={ wallGeometry }
                material={ wallCornerDesign } 
                position={ [5.05, 3.9, - 15] } 
                rotation-z={ - Math.PI * 0.5 }
                rotation-x={ - Math.PI * 0.5 }
                scale={ [ 10, 0.1, 0.2 ] }
            >
            </mesh>

            <mesh
                castShadow
                receiveShadow
                canSleep={ true } 
                geometry={ wallGeometry }
                material={ wallCornerDesign } 
                position={ [ - 5.05, - 0.9, - 15 ] } 
                rotation-z={ - Math.PI * 0.5 }
                rotation-x={ - Math.PI * 0.5 }
                scale={ [ 10, 0.1, 0.2 ] }
            >
            </mesh>

            <mesh
                castShadow
                receiveShadow
                canSleep={ true } 
                geometry={ wallGeometry }
                material={ wallCornerDesign } 
                position={ [ - 5.05, 3.9, - 15 ] } 
                rotation-z={ - Math.PI * 0.5 }
                rotation-x={ - Math.PI * 0.5 }
                scale={ [ 10, 0.1, 0.2 ] }
            >
            </mesh>

            {/**
            * Right Wall
            */}

            <mesh
                castShadow
                receiveShadow
                canSleep={ true } 
                geometry={ wallGeometry }
                material={ innerWall } 
                position={ [10.1, 1.5, - 30] } 
                rotation-z={ - Math.PI * 0.5 }
                rotation-x={ - Math.PI * 0.5 }
                scale={ [95, 0.2, 5] }
            >
            </mesh>

            <mesh
                castShadow
                receiveShadow
                canSleep={ true } 
                geometry={ wallGeometry }
                material={ wallCornerDesign } 
                position={ [9.95, - 0.9, - 30] } 
                rotation-z={ - Math.PI * 0.5 }
                rotation-x={ - Math.PI * 0.5 }
                scale={ [95, 0.1, 0.2] }
            >
            </mesh>

            {/**
            * Left Wall
            */}

            <mesh
                castShadow
                receiveShadow
                canSleep={ true } 
                geometry={ wallGeometry }
                material={ innerWall } 
                position={ [ - 10.1, 1.5, - 30] } 
                rotation-z={ - Math.PI * 0.5 }
                rotation-x={ - Math.PI * 0.5 }
                scale={ [95, 0.2, 5] }
            >
            </mesh>

            <mesh
                castShadow
                receiveShadow
                canSleep={ true } 
                geometry={ wallGeometry }
                material={ wallCornerDesign } 
                position={ [- 9.95, - 0.9, - 30] } 
                rotation-z={ - Math.PI * 0.5 }
                rotation-x={ - Math.PI * 0.5 }
                scale={ [95, 0.1, 0.2] }
            >
            </mesh>

            {/** Wall on the back */}
        
            <CuboidCollider canSleep args={ [ 10, 2, 0.5 ] } position={ [ - 0, 1, 9] } />
            
        </RigidBody>

        {/** Spotlight */}
            
        <FrameSpotLight />
            
    </>
}
