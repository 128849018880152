import { useGLTF, useAnimations, Box, Clone } from "@react-three/drei"
import { useEffect, useRef } from 'react'
import * as THREE from 'three'
import { CuboidCollider, Physics, RigidBody } from "@react-three/rapier"

const boxGeometry = new THREE.BoxGeometry(3, 0.3, 3)
const boxMaterial = new THREE.MeshStandardMaterial({ color: 'white'})
boxMaterial.receiveShadow = true

export default function Dinosaur()
{
    const Dinosaur = useGLTF('./../Museum/Blended/Dinosaur.glb')
    const SpotLight = useGLTF('./../Museum/Blended/SpotLight.glb')
    const SpotLightDownSide = useGLTF('./../Museum/Blended/SpotLightDownSide.glb')

    const animations = useAnimations(Dinosaur.animations, Dinosaur.scene)

    Dinosaur.scene.children.forEach((mesh) =>
    {
        mesh.castShadow = true
    })

    const mixer = useRef();

    useEffect((state) => 
    {
        const action = animations.actions.BodyAction

        action.play()

        const resetAnimations = () =>
        {
            window.setTimeout(() => 
            {
                animations.actions.TailAction.play()
                animations.actions.TailAction.crossFadeFrom(animations.actions.BodyAction, 1)
            }, 10000)

            action.reset().play()
        }

        resetAnimations()
    }, [ ])

    return <>
            
            <mesh 
                receiveShadow
                geometry={ boxGeometry }
                material={ boxMaterial }
                position={[ 7, - 0.8, -1]}
            />

            <CuboidCollider args={[ 1.5, 1.5, 1.5]} position={[ 7, 0.8, - 1]}/>
            <primitive 
                recieveShadow
                canSleep={ true } 
                object={ Dinosaur.scene }
                scale={ 2 }
                position={[ 7.07, -0.68, - 1 ]}
                rotation-y={ - 0.9 }
            />

            {/** Spotlight */}
            <primitive 
                object={ SpotLight.scene }
                scale={ 1 }
                position={[ 8.31, -0.68, - 2.3 ]}
                rotation-y={ - 0.7 }
            />

            <Clone 
                object={ SpotLight.scene }
                scale={ 1 }
                position={[ 5.72, -0.68, - 2.3 ]}
                rotation-y={ 0.7 }
            />

            <Clone 
                object={ SpotLight.scene }
                scale={ 1 }
                position={[ 5.72, -0.68, 0.25 ]}
                rotation-y={ Math.PI * 0.7 }
            />

            <Clone 
                object={ SpotLight.scene }
                scale={ 1 }
                position={[ 8.31, -0.68, 0.25 ]}
                rotation-y={ - Math.PI * 0.7 }
            />

            { /** For the fullname */ }
            <RigidBody colliders="cuboid">
                <primitive 
                    object={ SpotLightDownSide.scene }
                    scale={ 1 }
                    position={[ -7, -0.68, 0.3 ]}
                    rotation-y={ Math.PI * 1 }
                />

                <Clone 
                    object={ SpotLightDownSide.scene }
                    scale={ 1 }
                    position={[ - 2.2, -0.68, - 2.5 ]}
                    rotation-y={ - Math.PI * 0.6 }
                />
            </RigidBody>


    </>
}

useGLTF.preload("./../Museum/Blended/Dinosaur.glb");
useGLTF.preload("./../Museum/Blended/SpotLight.glb");
useGLTF.preload("./../Museum/Blended/SpotLightDownSide.glb");