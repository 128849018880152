import { useGLTF, Clone } from "@react-three/drei"
import { RigidBody } from "@react-three/rapier"

export default function Aderski({ position = [] })
{
    const Aderski = useGLTF('./Museum/Blended/AderskiLowVertices.glb')

    Aderski.scene.children.forEach((mesh) =>
    {
        mesh.castShadow = true
    })

    return <>
        {/** Aderski */}

    {/* <RigidBody type="fixed"> */}
        {/* <primitive
            object={ Aderski.scene }
            scale={ 0.8 }
            // rotation-y={ Math.PI * 1 }
            // rotation-x={ Math.PI * 0.5 }
            position={ position[0] }
        /> */}

        <Clone
            object={ Aderski.scene }
            scale={ 0.8 }
            rotation-y={ - Math.PI * 0.1 }
            // rotation-x={ Math.PI * 0.5 }
            position={ position[0] }
        />

        <Clone
            object={ Aderski.scene }
            scale={ 0.8 }
            rotation-y={ - Math.PI * 0.4 }
            // rotation-x={ Math.PI * 0.5 }
            position={ position[1] }
        />

        <Clone
            object={ Aderski.scene }
            scale={ 0.8 }
            rotation-y={ - Math.PI * 0.5 }
            // rotation-x={ Math.PI * 0.5 }
            position={ position[2] }
        />

        <Clone
            object={ Aderski.scene }
            scale={ 0.8 }
            // rotation-y={ Math.PI * 1 }
            // rotation-x={ Math.PI * 0.5 }
            position={ position[3] }
        />

        <Clone
            object={ Aderski.scene }
            scale={ 0.8 }
            rotation-y={ Math.PI * 0.4 }
            // rotation-x={ Math.PI * 0.5 }
            position={ position[4] }
        />

        <Clone
            object={ Aderski.scene }
            scale={ 0.8 }
            rotation-y={ - Math.PI * 0.5 }
            // rotation-x={ Math.PI * 0.5 }
            position={ position[5] }
        />

    {/* </RigidBody> */}

        {/** This is the Dinosaur Adeskis */}
        <Clone
            object={ Aderski.scene }
            scale={ 0.8 }
            rotation-y={ - Math.PI * 0.5 }
            position={[ 5.3, - 0.98, - 1.71 ]}
        />

        <Clone
            object={ Aderski.scene }
            scale={ 0.8 }
            rotation-y={ - Math.PI * 0.5 }
            position={[ 5.33, - 0.98, - 0.21 ]}
        />

        <Clone
            object={ Aderski.scene }
            scale={ 0.8 }
            rotation-y={ - Math.PI * 0.5 }
            position={[ 8.67, - 0.98, - 1.71 ]}
        />

        <Clone
            object={ Aderski.scene }
            scale={ 0.8 }
            rotation-y={ - Math.PI * 0.5 }
            position={[ 8.7, - 0.98, - 0.21 ]}
        />

        <Clone
            object={ Aderski.scene }
            scale={ 0.8 }
            rotation-y={ - Math.PI }
            position={[ 6.3, - 0.98, 0.7 ]}
        />

        <Clone
            object={ Aderski.scene }
            scale={ 0.8 }
            rotation-y={ - Math.PI }
            position={[ 7.7, - 0.98, 0.67 ]}
        />

        <Clone
            object={ Aderski.scene }
            scale={ 0.8 }
            rotation-y={ - Math.PI }
            position={[ 6.3, - 0.98, - 2.7 ]}
        />

        <Clone
            object={ Aderski.scene }
            scale={ 0.8 }
            rotation-y={ - Math.PI }
            position={[ 7.7, - 0.98, - 2.67 ]}
        />

    </>
}

useGLTF.preload('./Museum/Blended/AderskiLowVertices.glb')