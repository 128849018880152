import { useEffect } from "react";
import { useMatcapTexture, Text3D, useGLTF } from "@react-three/drei";
import { RigidBody, Physics } from '@react-three/rapier'
import * as THREE from 'three'

const material = new THREE.MeshMatcapMaterial()

export default function MyLastName()
{
    const myLogo = useGLTF('./Museum/Blended/MyLogo.glb')
    const reactLogo = useGLTF('./Museum/Blended/ReactFiber.gltf')

    const [ matcapTexture ] = useMatcapTexture('161B1F_C7E0EC_90A5B3_7B8C9B', 256)

    useEffect(() => {
        matcapTexture.colorSpace = THREE.SRGBColorSpace
        matcapTexture.needsUpdate = true

        material.matcap = matcapTexture
        material.needsUpdate = true
    }, [])

    myLogo.scene.traverse((child) => {
        if (child.isMesh) {
            child.material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture });
        }
    });

    return <>

        {/** My Logo */}
        <RigidBody 
            canSleep
            colliders="hull"
        >
            <primitive 
                scale={ 1 }
                object={ myLogo.scene }
                material={ material }
                position={ [- 6.7, 0.2, - 0.8 ] }
                rotation-y={ Math.PI * 0.2 }
            />
        </RigidBody>

        <group position-y={ - 1 } position-x={ - 6 } size={ 1 } rotation-y={ 0.5 }>
            <RigidBody 
                canSleep={ true } 
                colliders="cuboid"
                position={[ 1.96, 0.4, -1.2 ]}
                
            >
                <Text3D 
                    castShadow
                    material={ material }
                    font="./fonts/Writing Book_Regular.json"
                    size={ 0.5 }
                    bevelEnabled
                    bevelThickness={ 0.02 }
                >
                    P
                </Text3D>
            </RigidBody>
            <RigidBody 
                canSleep={ true } 
                colliders="cuboid"
                position={[ 2.19, 0.4, -1.2 ]}

            >
                <Text3D 
                    castShadow
                    material={ material }
                    font="./fonts/Writing Book_Regular.json"
                    size={ 0.5 }
                    bevelEnabled
                    bevelThickness={ 0.02 }
                    color={ ["white"] }
                >
                    A
                </Text3D>
            </RigidBody>
            <RigidBody 
                canSleep={ true } 
                colliders="cuboid"
                position={[ 2.48, 0.4, -1.2 ]}
            >
                <Text3D 
                    castShadow
                    material={ material }
                    font="./fonts/Writing Book_Regular.json"
                    size={ 0.5 }
                    bevelEnabled
                    bevelThickness={ 0.03 }
                    color={ ["white"] }
                >
                    T
                </Text3D>
            </RigidBody>
            <RigidBody 
                canSleep={ true } 
                colliders="cuboid"
                position={[ 2.71, 0.4, -1.2 ]}
            >
                <Text3D 
                    castShadow
                    material={ material }
                    font="./fonts/Writing Book_Regular.json"
                    size={ 0.5 }
                    bevelEnabled
                    bevelThickness={ 0.02 }
                    color={ ["white"] }
                >
                    A
                </Text3D>
            </RigidBody>
            <RigidBody 
                canSleep={ true } 
                colliders="cuboid"
                position={[ 3, 0.4, -1.2 ]}
            >
                <Text3D 
                    castShadow
                    material={ material }
                    font="./fonts/Writing Book_Regular.json"
                    size={ 0.5 }
                    bevelEnabled
                    bevelThickness={ 0.02 }
                    color={ ["white"] }
                >
                    W
                </Text3D>
            </RigidBody>
            <RigidBody 
                canSleep={ true } 
                colliders="cuboid"
                position={[ 3.34, 0.4, -1.2 ]}
            >
                <Text3D 
                    castShadow
                    material={ material }
                    font="./fonts/Writing Book_Regular.json"
                    size={ 0.5 }
                    bevelEnabled
                    bevelThickness={ 0.02 }
                    color={ ["white"] }
                >
                    A
                </Text3D>
            </RigidBody>
            <RigidBody 
                canSleep={ true } 
                colliders="cuboid"
                position={[ 3.6, 0.4, -1.2 ]}
            >
                <Text3D 
                    castShadow
                    material={ material }
                    font="./fonts/Writing Book_Regular.json"
                    size={ 0.5 }
                    bevelEnabled
                    bevelThickness={ 0.02 }
                    color={ ["white"] }
                >
                    R
                </Text3D>
            </RigidBody>
            <RigidBody 
                canSleep={ true } 
                colliders="cuboid"
                position={[ 3.83, 0.4, -1.2 ]}
            >
                <Text3D 
                    castShadow
                    material={ material }
                    font="./fonts/Writing Book_Regular.json"
                    size={ 0.5 }
                    bevelEnabled
                    bevelThickness={ 0.02 }
                    color={ ["white"] }
                >
                    A
                </Text3D>
            </RigidBody>
            <RigidBody 
                canSleep={ true } 
                colliders="cuboid"
                position={[ 4.11, 0.4, -1.2 ]}
            >
                <Text3D 
                    castShadow
                    material={ material }
                    font="./fonts/Writing Book_Regular.json"
                    size={ 0.5 }
                    bevelEnabled
                    bevelThickness={ 0.02 }
                    color={ ["white"] }
                >
                    N
                </Text3D>
            </RigidBody>
        </group>
        
    </>
    
}

useGLTF.preload("./Museum/Blended/MyLogo.glb");