import create from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

export default create(subscribeWithSelector((set) => 
{
    return {
        title: '',
        phase: 'ready',

        start: () =>
        {
            set((state) =>
            {
                if(state.phase === 'ready')
                    return { phase: 'playing' }

                return {}
            })
        },

        restart: () => 
        {
            set((state) => 
            {
                if(state.phase === 'playing' || state.phase === 'ended')
                    return { phase: 'ready', blocksSeed: Math.random() }

                return {}
            })
        },

        email: () =>
        {
            set((state) =>
            {
                if(state.phase === 'playing')
                    return { title: 'email', description: 'anjurypatawaran@gmail.com' }

                return {}
                
            })
        },

        linkedin: () =>
        {
            set((state) =>
            {
                if(state.phase === 'playing')
                    return { title: 'linkedin', description: 'www.linkedin.com/in/anjury-patawaran-81a18414a' }

                return {}
                
            })
        },

        contact: () =>
        {
            set((state) =>
            {
                if(state.phase === 'playing')
                    return { title: 'contact', description: '(0915) 233 2745' }

                return {}
                
            })
        },

        remove: () =>
        {
            set((state) =>
            {
                if(state.phase === 'playing')
                    return { title: 'remove', description: '' }

                return {}
                
            })
        },

        // restart: () =>
        // {
        //     set(() =>
        //     {
        //         return { title: 'ready' }
        //     })
        // },

        // end: () =>
        // {
        //     set(() =>
        //     {
        //         return { title: 'ended' }
        //     })
        // } 
    }
}))