import './style.css'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import Experience from './Experience.jsx'
import { KeyboardControls } from '@react-three/drei'
import Interface from './Interface.jsx'
import { Suspense } from 'react'
import Loader from './Loader.jsx'

const root = ReactDOM.createRoot(document.querySelector('#root'))

function isMobile() {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
return regex.test(navigator.userAgent);
}
if (isMobile()) {
    alert("Mobile device detected");
    console.log("Mobile device detected");
} else {
    root.render(
        <KeyboardControls
            map={[
              { name: "forward", keys: ["ArrowUp", "w", "W"] },
              { name: "backward", keys: ["ArrowDown", "s", "S"] },
              { name: "leftward", keys: ["ArrowLeft", "a", "A"] },
              { name: "rightward", keys: ["ArrowRight", "d", "D"] },
              { name: "jump", keys: ["Space"] },
            ]}>
            <Canvas shadows camera={{ fov: 45 }}>
                <Suspense fallback={<Loader />}>
                    <Experience />
                </Suspense>
            </Canvas>
    
            <Interface />
            
        </KeyboardControls>
    )
}

