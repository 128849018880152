import { Text3D, Text, useMatcapTexture, Float } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";
import * as THREE from 'three'
import { useEffect } from "react";

export default function Projects()
{
    // const [ matcapTexture ] = useMatcapTexture('161B1F_C7E0EC_90A5B3_7B8C9B', 256)
    
    // useEffect(() => 
    // {
    //     matcapTexture.colorSpace = THREE.SRGBColorSpace
    //     matcapTexture.needsUpdate = true

    //     material.matcap = matcapTexture
    //     material.needsUpdate = true
    // })

    // const material = new THREE.MeshMatcapMaterial()

    return <>
        <RigidBody
        >
            <Text 
                color={ "black" }
                font="./fonts/WritingBook-1GwXM.otf"
                scale={ 2.4 }
                maxWidth={ 4.4 }
                position={ [ 5.03, 2.5, - 13 ] }
                rotation-y={ 1.56 }
            >
                PROJECTS 
                {/* <meshBasicMaterial toneMapped={ false } color={ "black" }/> */}
            </Text>

            <Text 
                color={ "black" }
                font="./fonts/WritingBook-1GwXM.otf"
                scale={ 2.4 }
                maxWidth={ 4.4 }
                position={ [ - 5.03, 2.5, - 16 ] }
                rotation-y={ - 1.56 }
            >
                PROJECTS 
                {/* <meshBasicMaterial toneMapped={ false } color={ "black" }/> */}
            </Text>

            <Text 
                color={ "black" }
                font="./fonts/WritingBook-1GwXM.otf"
                scale={ 2.4 }
                maxWidth={ 4.4 }
                position={ [ 5.03, 0.4, - 17.2 ] }
                rotation-y={ 1.56 }
            >
                SECTION 
            </Text>

            <Text 
                color={ "black" }
                font="./fonts/WritingBook-1GwXM.otf"
                scale={ 2.4 }
                maxWidth={ 4.4 }
                position={ [ - 5.03, 0.4, - 13 ] }
                rotation-y={ - 1.56 }
            >
                SECTION 
            </Text>

            <Text 
                color={ "black" }
                font="./fonts/Kitisakkullian.otf"
                scale={ 0.1 }
                maxWidth={ 33 }
                position={ [ 5.03, 2.6, - 17.7 ] }
                rotation-y={ 1.56 }
                anchorX="center" 
                anchorY="middle"
            >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud 
                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute 
                irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla 
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia 
                deserunt mollit anim id est laborum
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud 
                exercitation ullamco laboris nisi ut aliquip ex ea commo
            </Text>

            <Text 
                color={ "black" }
                font="./fonts/Kitisakkullian.otf"
                scale={ 0.1 }
                maxWidth={ 30 }
                position={ [ - 5.03, 2.6, - 11.5 ] }
                rotation-y={ - 1.56 }
                anchorX="center" 
                anchorY="middle"
            >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud 
                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute 
                irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla 
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia 
                deserunt mollit anim id est laborum
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud 
                exercitation ullamco laboris nisi ut aliquip ex ea commo
            </Text>

            <Text 
                color={ "black" }
                font="./fonts/Kitisakkullian.otf"
                scale={ 0.1 }
                maxWidth={ 42 }
                position={ [ 5.03, 0.4, - 12.2 ] }
                rotation-y={ 1.56 }
                anchorX="center" 
                anchorY="middle"
            >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud 
                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute 
                irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla 
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia 
                deserunt mollit anim id est laborum
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud 
                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute 
                irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla 
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia 
                deserunt mollit anim id est laborum
            </Text>

            <Text 
                color={ "black" }
                font="./fonts/Kitisakkullian.otf"
                scale={ 0.1 }
                maxWidth={ 36 }
                position={ [ - 5.03, 0.5, - 17.2 ] }
                rotation-y={ - 1.56 }
                anchorX="center" 
                anchorY="middle"
            >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud 
                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute 
                irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla 
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia 
                deserunt mollit anim id est laborum
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud 
                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute 
                irure dolor in reprehenderit in voluptate velit esse ci
            </Text>

            {/* <Float floatIntensity={ 0.5 } rotationIntensity={ 0.1 } speed={ 5 }>
                <Text 
                    material={ material }
                    font="./fonts/WritingBook-1GwXM.otf"
                    scale={ 0.8 }
                    maxWidth={ 5 }
                    // textAlign='center'
                    position={ [ 7.5, - 0.8, - 16.88 ] }
                    rotation-x={ 0 }
                >
                    PROJECTS SECTION
                </Text>
            </Float> */}
        </RigidBody>
    </>
}