import { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three'
import { Box, useGLTF, Text, Clone, useHelper, Sparkles } from '@react-three/drei';
import { BallCollider, RigidBody } from '@react-three/rapier';
import BlinkingText from './BlinkingText';
import Aderski from '../assets/Aderski.jsx';
import Streetname from '../floor/Streetname.jsx';

const boxGeometry = new THREE.BoxGeometry(1, 1, 1)
const floor = new THREE.MeshStandardMaterial({color: '#c1b042'})

export default function Mid()
{
        const LabMonitor = useGLTF('./Museum/Blended/LabMonitor.glb')

        const PaperPlane = useGLTF('./Museum/Blended/PaperPlane.glb')

        const TreeBeech = useGLTF('./Museum/Blended/TreeBeech.gltf')

        const MediumMenhhir = useGLTF('./Museum/Blended/MediumMenhhir.gltf')
        const SmallMenhir = useGLTF('./Museum/Blended/SmallMenhir.gltf')

        const TreeSpurce = useGLTF('./Museum/Blended/TreeSpurce.gltf')

        const Bench = useGLTF('./Museum/Blended/Bench.gltf')

        const Monitor = useGLTF('./Museum/Blended/Monitor.glb')

        Monitor.scene.children.forEach((mesh) =>
        {
            mesh.castShadow = true
        })

        const paperplaneRef = useRef()

        const clockRef = useRef(new THREE.Clock());

        TreeBeech.scene.children.forEach((mesh) => 
        {
            mesh.castShadow = true
        })

        TreeSpurce.scene.children.forEach((mesh) => 
        {
            mesh.castShadow = true
        })

        MediumMenhhir.scene.children.forEach((mesh) =>
        {
            mesh.receiveShadow = true
        })

        useFrame(() => {
            {/** Adjust the rotation speed by changing the divisor */}
            const rotationSpeed = 0.5;
            const movementSpeed = 1;
      
            {/** Get elapsed time from the clock */}
            const elapsedTime = clockRef.current.getElapsedTime();
        
            {/** Rotate the mesh */}
            
            paperplaneRef.current.position.x = Math.sin(elapsedTime * movementSpeed) + 0.7
            paperplaneRef.current.position.y = Math.cos(elapsedTime) * movementSpeed - 0.2

            paperplaneRef.current.rotation.x = - Math.sin(elapsedTime * movementSpeed) * 2
            paperplaneRef.current.rotation.y = - Math.sin(elapsedTime * movementSpeed) * 2
        
            {/** Reset the rotation after a certain duration (e.g., 5 seconds) */}
            if (elapsedTime > 2) {
                clockRef.current.start(); {/** Reset the clock */}
            }
        }, [ paperplaneRef ]);

      return <>

            <BlinkingText 
                position={ [ 0.53, 0.89, - 48.1 ] }
            />
            
            <primitive 
                object={ LabMonitor.scene }
                scale={ 0.5 }
                position={ [ 0.5, 0.5, - 50 ] }
            />

            {/** TREES */}

            <primitive 
                object={ TreeBeech.scene }
                scale={ 0.2 }
                position={[ - 0.8, - 1, - 50 ]}
                rotation-y={ Math.PI * 0.3 }
            />

            <Clone 
                castShadow
                object={ TreeBeech.scene }
                scale={ 0.2 }
                position={[ - 1.8, - 1, - 48.6 ]}
                rotation-y={ - Math.PI * 1 }
            />

            <primitive 
                object={ Bench.scene }
                scale={ 0.5 }
                position={[ - 1.4, - 1, - 48.6 ]}
                rotation-y={ Math.PI * 0.2 }
            />

            <Clone 
                object={ Bench.scene }
                scale={ 0.5 }
                position={[ 3.2, - 1, - 48.6 ]}
                rotation-y={ Math.PI * 0.8 }
            />

            <primitive 
                object={ TreeSpurce.scene }
                scale={ 0.14 }
                position={[ 3.4, - 1, - 49.6 ]}
                rotation-y={ - Math.PI * 0.1 }
            />

            {/** Menhir */}
            <primitive 
                object={ MediumMenhhir.scene }
                scale={ 0.3 }
                position={[ 2.4, - 0.6, - 49.6 ]}
            />

            <Clone 
                object={ MediumMenhhir.scene }
                scale={ 0.3 }
                position={[ 3.3, - 0.6, - 50.6 ]}
                rotation-y={ Math.PI * 0.2 }
            />

            <primitive 
                object={ SmallMenhir.scene }
                scale={ 0.2 }
                position={[ 4, - 0.8, - 49.6 ]}
            />

            {/** PAPER PLANE */}
            <primitive 
                ref={ paperplaneRef }
                object={ PaperPlane.scene }
                scale={ 0.3 }
                position={ [ 0.5, 1.6, - 48.2 ] }
                rotation-y={ Math.PI }
                rotation-x={ Math.PI * 0.5}
            />

            {/** SPARKLES */}
            <Sparkles
		        size={ 5 }
                scale={ [ 5, 4, 4 ] }
                speed={ 0.2 }
                count={ 100 }
                color={ "#5CB3FF" }
                position={ [ 0.5, 1, - 48.2 ] }
            />

            <BallCollider 
                args={[ 4.6 ]} 
                position={ [ 1, - 1.65, - 49.2 ] }
                restitution={ 0.1 } 
                friction={ 0.1 }
            />

            {/** Monitor */}
            
            <RigidBody 
                type='fixed' 
                colliders="cuboid" 
                restitution={ 0.1 }
                friction={ 0.1 }
            >
                <Clone 
                    object={ Monitor.scene }
                    scale={ 0.5 }
                    position={ [ - 1.1, - 0.51, - 45.2 ] }
                />
            </RigidBody>

            <Text
                font="./fonts/MinionPro-Semibold.otf"
                scale={ 0.08 }
                maxWidth={ 5 }
                color={ "black" }
                textAlign='center'
                position={ [ - 1.1, - 0.16, - 45.15 ] }
            >
                Medical Information
            </Text>

            <Text
                font="./fonts/Lato-Thin.ttf"
                scale={ 0.06 }
                maxWidth={ 8.5 }
                color={ "black" }
                textAlign='center'
                position={ [ - 1.1, - 0.55, - 45.15 ] }
            >
                A system that stores Information of the members with uploading of the members using encryption and downloading It encrypted.
            </Text>

            {/** ADERSKI */}

            <Aderski 
                position={ 
                    [
                        [ - 1.4, - 1, - 45.9 ], 
                        [ - 2.7, - 1, - 46.9 ],
                        [ - 3.1, - 1, - 48.9 ],
                        [ 3.5, - 1, - 45.9 ],
                        [ 4.65, - 1, - 46.9 ],
                        [ 5, - 1, - 48.9 ]
                    ] 
                }
            />

            <Text
                color="white"
                scale={ 0.35 }
                position={ [ 5.9, 3.61, - 55 ] }
                font="./fonts/WritingBook-1GwXM.otf"
                maxWidth={ 10 }
            >
                CONTACT SECTION
            </Text>

            <Text
                color="white"
                scale={ 0.35 }
                position={ [ - 5.9, 3.61, - 55 ] }
                font="./fonts/WritingBook-1GwXM.otf"
                maxWidth={ 10 }
            >
                CONTACT SECTION
            </Text>

            <Streetname 
                position=
                    { 
                        [
                            [ 6, 3.61, - 55.05 ],
                            [ - 6, 3.61, - 55.05 ]
                        ] 
                    } 
                rotation=
                    { 
                        [
                            [0, - Math.PI * 0.5, 0],
                            [0, Math.PI * 0.5, 0]
                        ]
                        
                    } 
                scale=
                    { 
                        [
                            1.3,
                            1.3
                        ] 
                    }
            />
        </>
}

useGLTF.preload('./Museum/Blended/LabMonitor.glb')
useGLTF.preload('./Museum/Blended/PaperPlane.glb')
useGLTF.preload('./Museum/Blended/TreeBeech.gltf')
useGLTF.preload('./Museum/Blended/MediumMenhhir.gltf')
useGLTF.preload('./Museum/Blended/SmallMenhir.gltf')
useGLTF.preload('./Museum/Blended/TreeSpurce.gltf')
useGLTF.preload('./Museum/Blended/Bench.gltf')
useGLTF.preload('./Museum/Blended/Monitor.glb')