import { useGLTF, Clone } from "@react-three/drei";

export default function Streetname({ position = [], rotation = [], scale = [] })
{
    const Signage = useGLTF('./Museum/Blended/Signage.glb')

    return <>
        <primitive 
            object={ Signage.scene }
            scale={ scale[0] }
            position={ position[0] }
            rotation={ rotation[0] }
        />

        <Clone 
            object={ Signage.scene }
            scale={ scale[1] }
            position={ position[1] }
            rotation={ rotation[1] }
        />
    </>
}

useGLTF.preload('./Museum/Blended/Signage.glb')