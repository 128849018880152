import * as THREE from 'three'
import { RigidBody } from '@react-three/rapier'
import { useMatcapTexture, Text3D, useGLTF } from '@react-three/drei'
import { useEffect } from 'react'

const material = new THREE.MeshMatcapMaterial()

export default function MyFirstName()
{
    const [ matcapTexture ] = useMatcapTexture('161B1F_C7E0EC_90A5B3_7B8C9B', 256)

    useEffect(() => 
    {
        matcapTexture.colorSpace = THREE.SRGBColorSpace
        matcapTexture.needsUpdate = true

        material.matcap = matcapTexture
        material.needsUpdate = true
    })
    
    return <>
            <RigidBody>
                <mesh 
                    material={ material }
                    position={ [- 5.55, - 0.8, - 1.51] }
                    rotation-y={ 0.5 }
                >
                    <boxGeometry args={[ 1.6, 0.3, 0.3]}/>
                </mesh>
            </RigidBody>

            <group position-y={ - 1 } position-x={ - 6 } size={ 1 } rotation-y={ 0.5 }>
                <RigidBody
                    canSleep={ true }
                    colliders="cuboid"
                    position={[ 0.4, 0.4, -1.2 ]}
                >
                    <Text3D
                        castShadow
                        material={ material }
                        font="./fonts/Writing Book_Regular.json"
                        size={ 0.5 }
                        bevelEnabled
                        bevelThickness={ 0.02 }
                    >
                        A
                    </Text3D>
                </RigidBody>
                <RigidBody
                    canSleep={ true }
                    colliders="cuboid"
                    position={[ 0.68, 0.4, -1.2 ]}
                >
                    <Text3D
                        castShadow
                        material={ material }
                        font="./fonts/Writing Book_Regular.json"
                        size={ 0.5 }
                        bevelEnabled
                        bevelThickness={ 0.02 }
                    >
                        N
                    </Text3D>
                </RigidBody>
                <RigidBody
                    canSleep={ true }
                    colliders="cuboid"
                    position={[ 0.96, 0.4, -1.2 ]}
                >
                    <Text3D
                        castShadow
                        material={ material }
                        font="./fonts/Writing Book_Regular.json"
                        size={ 0.5 }
                        bevelEnabled
                        bevelThickness={ 0.02 }
                    >
                        J
                    </Text3D>
                </RigidBody>
                <RigidBody
                    canSleep={ true }
                    colliders="cuboid"
                    position={[ 1.15, 0.4, -1.2 ]}
                >
                    <Text3D
                        castShadow
                        material={ material }
                        font="./fonts/Writing Book_Regular.json"
                        size={ 0.5 }
                        bevelEnabled
                        bevelThickness={ 0.02 }
                    >
                        U
                    </Text3D>
                </RigidBody>
                <RigidBody
                    canSleep={ true }
                    colliders="cuboid"
                    position={[ 1.42, 0.4, -1.2 ]}
                >
                    <Text3D
                        castShadow
                        material={ material }
                        font="./fonts/Writing Book_Regular.json"
                        size={ 0.5 }
                        bevelEnabled
                        bevelThickness={ 0.02 }
                    >
                        R
                    </Text3D>
                </RigidBody>
                <RigidBody
                    canSleep={ true }
                    colliders="cuboid"
                    position={[ 1.65, 0.4, -1.2 ]}
                >
                    <Text3D
                        castShadow
                        material={ material }
                        font="./fonts/Writing Book_Regular.json"
                        size={ 0.5 }
                        bevelEnabled
                        bevelThickness={ 0.02 }
                    >
                        Y
                    </Text3D>
                </RigidBody>
            </group>
    </>
}

useGLTF.preload()