import * as THREE from "three"
import * as RAPIER from "@dimforge/rapier3d-compat"
import { useRef, useState, useEffect } from "react"
import { useFrame } from "@react-three/fiber"
import { useKeyboardControls } from "@react-three/drei"
import { CapsuleCollider, RigidBody, useRapier } from "@react-three/rapier"
import usePort from "./stores/usePort"

const SPEED = 5
const direction = new THREE.Vector3()
const frontVector = new THREE.Vector3()
const sideVector = new THREE.Vector3()
const rotation = new THREE.Vector3()

export function Player({ lerp = THREE.MathUtils.lerp }) 
{

  const axe = useRef()
  const ref = useRef()
  const rapier = useRapier()
  const [, get] = useKeyboardControls()

  const [ subscribeKeys, getKeys ] = useKeyboardControls()

  const start = usePort((state) => state.start)
  // const end = usePort((state) => state.end)

  const email = usePort((state) => state.email)
  const linkedin = usePort((state) => state.linkedin)
  const contact = usePort((state) => state.contact)

  const remove = usePort((state) => state.remove)

  useFrame((state) => {
    const { forward, backward, leftward, rightward, jump } = get()
    const velocity = ref.current.linvel()
    
    const bodyPosition = ref.current.translation()
    // console.log(Math.floor(bodyPosition.z));
    // console.log(bodyPosition.x.toFixed(2));
    {/** Update Camera */}
    state.camera.position.set(...ref.current.translation())
    frontVector.set(0, 0, backward - forward)
    sideVector.set(leftward - rightward, 0, 0)
    direction.subVectors(frontVector, sideVector).normalize().multiplyScalar(SPEED).applyEuler(state.camera.rotation)
    ref.current.setLinvel({ x: direction.x, y: velocity.y, z: direction.z })
    
    {/** Jumping */}
    const world = rapier.world.raw()
    const ray = world.castRay(new RAPIER.Ray(ref.current.translation(), { x: 0, y: -1, z: 0 }))
    const grounded = ray && ray.collider && Math.abs(ray.toi) <= 1.75
    if (jump && grounded) ref.current.setLinvel({ x: 0, y: 7.5, z: 0 })

    if(Math.floor(bodyPosition.z) == -71 && (Math.round(bodyPosition.x).toFixed(2) >= -1 && Math.round(bodyPosition.x).toFixed(2) <= -0.74))
    {
      email()
    } else if(Math.floor(bodyPosition.z) == -72 && (Math.round(bodyPosition.x).toFixed(2) >= 0.90 && Math.round(bodyPosition.x).toFixed(2) <= 1.15))
    {
      contact()
    } else if(Math.floor(bodyPosition.z) == -71 && (Math.round(bodyPosition.x).toFixed(2) >= 2.80 && Math.round(bodyPosition.x).toFixed(2) <= 3.20))
    {
      linkedin()
    }else 
    {
      remove()
    }
      
    if(Math.floor(bodyPosition.y) < -4)
    {
      ref.current.setTranslation({ x: 0, y: 5, z: 7 })
    }

            
  })

  useEffect(() =>
  {
    const unsubscribeAny = subscribeKeys(
      () => 
      {
        start()
      }
    )

    return () => 
    {
      unsubscribeAny()
    }

  }, [])
  return (
    <>
    {/** 0, 1, 7 */}
      <RigidBody ref={ref} colliders={false} mass={1} type="dynamic" position={[0, 5, 7]} enabledRotations={[false, false, false]}> 
        <CapsuleCollider castShadow args={[0.75, 0.5]} />
      </RigidBody>
    </>
  )

}

