import { Clone, useGLTF, Text } from '@react-three/drei'
import { RigidBody } from '@react-three/rapier'

import * as THREE from 'three'
import MyLastName from '../fullname/MyLastName.jsx'
import MyFirstName from '../fullname/MyFirstName.jsx'
import MyFrame from './MyFrame.jsx'
import Contact from '../sections/Contact.jsx'
import Hris from '../hris/Hris.jsx'
import Projects from '../sections/Projects.jsx'
import Mid from '../mid/Mid.jsx'

export default function MuseumMats()
{
    const boxGeometry = new THREE.BoxGeometry(1, 1, 1)
    const floor = new THREE.MeshStandardMaterial({color: '#c1b042'})

    // const MuseumLight = useGLTF('./Museum/Blended/MuseumLight.glb')
    
    // MuseumLight.scene.children.forEach((mesh) => 
    // {
    //     mesh.castShadow = true
    // })

    const BrickWall = useGLTF('./Museum/Blended/BrickWall.gltf')

    return <>

            <MyFirstName />
            <MyLastName />

            {/* <RigidBody type="fixed" colliders="trimesh">
                <primitive 
                    canSleep={ true } 
                    castShadow
                    object={ MuseumLight.scene } 
                    rotation-x={ Math.PI * 1 }
                    rotation-y={ Math.PI * 1 }
                    scale={ 0.51 }
                    position={ [ - 0, 1.30, - 10.02 ] }
                    
                />

                <Clone 
                    canSleep={ true } 
                    castShadow
                    object={ MuseumLight.scene }
                    scale={ 0.51 }
                    rotation-y={ Math.PI * 2 }
                    rotation-x={ Math.PI * 2 }
                    position={ [ - 0, 1.75, - 10.02 ] }
                />
            </RigidBody> */}

            <MyFrame />
            
            <Text 
                font="./fonts/PTSans-Regular.ttf"
                scale={ 0.25 }
                maxWidth={ 24 }
                position={ [ 1.8, 1.62, - 9.88 ] }
            >
                Creative and efficient web developer with 5+ years’ experience working on both front-end and back-end web development. Eager to contribute my skills and experience to create web experiences and solve complex challenges in a dynamic development environment.
                <meshBasicMaterial toneMapped={ false } color={ "black" }/>
            </Text>

            {/** Brick Wall */}
            {/* <RigidBody type='kinematicPosition' colliders="hull" restitution={ 0.2 } friction={ 0.2 }> */}
                <group>
                    {/* <primitive 
                        recieveShadow
                        canSleep={ true } 
                        object={ BrickWall.scene } 
                        scale={ 1.26 }
                        rotation-y={ - Math.PI * 0.5 }
                        position={ [ 10.63, - 1, - 12.02 ] }
                        
                    />

                    <Clone 
                        canSleep={ true } 
                        castShadow
                        object={ BrickWall.scene } 
                        scale={ 1.26 }
                        rotation-y={ - Math.PI * 0.5 }
                        position={ [ 10.63, - 1, - 17 ] }
                        
                    /> */}
                </group>

                <group>
                    {/* <Clone 
                        recieveShadow
                        canSleep={ true } 
                        object={ BrickWall.scene } 
                        scale={ 1.26 }
                        position={ [ - 10.63, - 1, - 12.02 ] }
                        rotation-y={ Math.PI * 0.5 }
                    />

                    <Clone 
                        canSleep={ true } 
                        castShadow
                        object={ BrickWall.scene } 
                        scale={ 1.26 }
                        position={ [ - 10.63, - 1, - 17 ] }
                        rotation-y={ Math.PI * 0.5 }
                    /> */}
                </group>
            {/* </RigidBody> */}

            {/** HRIS */}
            <Projects />

                <Hris />

                <Mid />
                
            <Contact />
        
    </>
}

// useGLTF.preload("./../Museum/Blended/MuseumLight.glb");
useGLTF.preload("./../Museum/Blended/BrickWall.gltf");