import * as THREE from 'three'
import { useMatcapTexture, Text, Text3D, Float, useGLTF, Clone, SpotLight, Plane } from '@react-three/drei'
import { RigidBody } from '@react-three/rapier'
import { useEffect, useRef, useState } from 'react'
import { useFrame } from '@react-three/fiber'
import { Player } from '../Player.jsx'

const cylinderGeometry = new THREE.CylinderGeometry(0.01, 0.01, 3, 10)
const cylinderMaterial = new THREE.MeshStandardMaterial({ color: '#d3d1ce'})

const animeStandGeometry = new THREE.CylinderGeometry(1, 1, 0.2, 25)
const animeStandMaterial = new THREE.MeshStandardMaterial({ color: '#a1a9ad'})

const loadingManager = new THREE.LoadingManager()

const textureLoader = new THREE.TextureLoader(loadingManager)

const MuseumStandMaterial = new THREE.MeshStandardMaterial()

export default function Contact()
{
    const MailLogo = useGLTF('./Museum/Blended/MailLogo.glb')
    const ContactLogo = useGLTF('./Museum/Blended/ContactLogo.glb')
    const LinkedInLogo = useGLTF('./Museum/Blended/LinkedInLogo.glb')

    const AnimePose = useGLTF('./Museum/Blended/AnimePose.glb')
    const GamingPose = useGLTF('./Museum/Blended/GamingPose.glb')

    const LampLight = useGLTF('./Museum/Blended/LampLightForLogo.glb')

    const MuseumDescriptionDesk = useGLTF('./Museum/Blended/MuseumDescriptionDesk.glb')

    const Circle = useGLTF('./Museum/Blended/Circle.glb')

    const movingGamingPoseRef = useRef()
    const movingVolleyballPoseRef = useRef()
    const movingAnimePoseRef = useRef()

    const clockRef = useRef(new THREE.Clock());
    
    MuseumDescriptionDesk.scene.children.forEach((mesh) => 
    {
        mesh.castShadow = true
    })

    AnimePose.scene.children.forEach((mesh) => 
    {
        mesh.castShadow = true
    })

    Circle.scene.traverse((child) => {
        if (child.isMesh) {
          child.material = child.material.clone();
          child.material.opacity = 0.4;
        }
    });

    const animeRef = useRef()
    const gamingRef = useRef()
    const standhereRef = useRef()

    useFrame(({ clock })=>
    {
        const elapsedTime = clockRef.current.getElapsedTime()
        
        animeRef.current.rotation.y = elapsedTime * 0.2
        gamingRef.current.rotation.y = - elapsedTime * 0.2
    }) 

    return <>
        {/** Anime Pose */}
        <RigidBody type='fixed'>
            <primitive 
                ref={ animeRef }
                object={ AnimePose.scene }
                scale={ 0.4 }
                position={ [ - 8.25, 0.46, - 75.5 ] }
            />

                {/** Anime Stand */}

                <mesh
                    castShadow
                    receiveShadow
                    geometry={ animeStandGeometry }
                    material={ animeStandMaterial }
                    position={ [ - 8.2, - 0.89, - 75.5 ] }
                />
        </RigidBody>

        {/** Gaming Pose */}
        <RigidBody type='fixed'>
            <primitive 
                ref={ gamingRef }
                object={ GamingPose.scene }
                scale={ 0.25 }
                position={ [ 8.5, - 0.7, - 75.3 ] }
            />

            <mesh
                castShadow
                receiveShadow
                geometry={ animeStandGeometry }
                material={ animeStandMaterial }
                position={ [ 8.5, - 0.89, - 75.5 ] }
            />
        </RigidBody>

        <RigidBody type='fixed' friction={ 0.1 } restitution={ 0.1 }>

            <primitive
                scale={ 0.5 }
                object={ MuseumDescriptionDesk.scene }
                position={ [- 1, -1, -71] }
                opacity={ 0.1 }
            />

            <Clone 
                object={ MuseumDescriptionDesk.scene }
                scale={ 0.5 }
                position={ [ 1, - 1, - 72 ] }
            />

            <Clone
                scale={ 0.5 }
                object={ MuseumDescriptionDesk.scene }
                position={ [3, - 1, -71] }
            />

        </RigidBody>

        <Text 
            color={ "black" }
            font="./fonts/Kitisakkullian.otf"
            scale={ 0.01 }
            maxWidth={ 20 }
            position={ [ 3, -0.31, -70.8 ] }
            // rotation-y={ 1.56 }
            anchorX="center" 
            anchorY="middle"
        >
            LinkedIn
        </Text>

        <Text 
            color={ "black" }
            font="./fonts/Kitisakkullian.otf"
            scale={ 0.01 }
            maxWidth={ 20 }
            position={ [ 1, -0.30, -71.79 ] }
            // rotation-y={ 1.56 }
            anchorX="center" 
            anchorY="middle"
        >
            Contact
        </Text>

        <Text 
            color={ "black" }
            font="./fonts/Kitisakkullian.otf"
            scale={ 0.01 }
            maxWidth={ 20 }
            position={ [ - 1, -0.31, -70.8 ] }
            // rotation-y={ 1.56 }
            anchorX="center" 
            anchorY="middle"
        >
            Mail
        </Text>

        {/* Stepping Circle */}
        <primitive
            scale={ 0.3 }
            object={ Circle.scene }
            position={ [- 1, -1, -70.3] }
        />

        <Clone 
            object={ Circle.scene }
            scale={ 0.3 }
            position={ [ 1, - 1, - 71.3 ] }
        />

        <Clone
            scale={ 0.3 }
            object={ Circle.scene }
            position={ [3, - 1, -70.3] }
        />

        <SpotLight
            castShadow
            target={ MailLogo.scene }
            distance={ 2 }
            angle={0.10}
            attenuation={2}
            opacity={ 0.8 }
            anglePower={1}
            position={ [- 1, 0.8, -71] }
            color={ '#b9b2a6' }
        />

        <SpotLight
            castShadow
            target={ ContactLogo.scene }
            distance={ 2 }
            angle={0.10}
            attenuation={2}
            opacity={ 0.8 }
            anglePower={1}
            position={ [ 1, 0.8, -72 ] }
            color={ '#b9b2a6' }
        />

        <SpotLight
            castShadow
            target={ LinkedInLogo.scene }
            distance={ 2 }
            angle={0.10}
            attenuation={2}
            opacity={ 0.8 }
            anglePower={1}
            position={ [ 3, 0.8, -71 ] }
            color={ '#b9b2a6' }
        />

        {/** MAIL LOGO */}
        <Float floatIntensity={ 0.01 } rotationIntensity={ 0.01 }>
            <primitive 
                object={ MailLogo.scene }
                scale={ 0.05 }
                position={ [- 1, 0.2, -71] }
            />
        </Float>

        {/** CONTACT LOGO */}
        <Float floatIntensity={ 0.01 } rotationIntensity={ 0.01 }>
            <primitive 
                object={ ContactLogo.scene }
                scale={ 0.05 }
                position={ [ 1, 0.2, - 72 ] }
            />
        </Float>

        <Float floatIntensity={ 0.01 } rotationIntensity={ 0.01 }>
        {/** LinkedIn LOGO */}
            <primitive 
                object={ LinkedInLogo.scene }
                scale={ 0.05 }
                position={ [ 3, 0.2, - 71 ] }
            />
        </Float>

        {/** Lamp Support from left to right sequence */}
        <mesh
            geometry={ cylinderGeometry }
            material={ cylinderMaterial }
            position={ [- 1, 2.5, -71] }
        />

        <mesh
            geometry={ cylinderGeometry }
            material={ cylinderMaterial }
            position={ [1, 2.5, -72] }
        />

        <mesh
            geometry={ cylinderGeometry }
            material={ cylinderMaterial }
            position={ [3, 2.5, -71] }
        />

        {/** Lamp Light */}
        <primitive
            scale={ 0.27 }
            material={ MuseumStandMaterial }
            object={ LampLight.scene }
            target={ MailLogo.scene }
            position={ [- 1, 0.8, -71] }
        />

        <Clone
            scale={ 0.27 }
            object={ LampLight.scene }
            position={ [1, 0.8, -72] }
        />

        <Clone
            scale={ 0.27 }
            object={ LampLight.scene }
            position={ [3, 0.8, -71] }
        />
        
    </>
}

useGLTF.preload('./Museum/Blended/MailLogo.glb')
useGLTF.preload('./Museum/Blended/ContactLogo.glb')
useGLTF.preload('./Museum/Blended/LinkedInLogo.glb')
useGLTF.preload('./Museum/Blended/AnimePose.glb')
useGLTF.preload('./Museum/Blended/GamingPose.glb')
useGLTF.preload('./Museum/Blended/LampLightForLogo.glb')
useGLTF.preload('./Museum/Blended/MuseumDescriptionDesk.glb')
useGLTF.preload('./Museum/Blended/Circle.glb')