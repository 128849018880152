import { useKeyboardControls  } from '@react-three/drei'
import usePort from './stores/usePort'

export default function Interface()
{
    const forward = useKeyboardControls((state) => state.forward)
    const backward = useKeyboardControls((state) => state.backward)
    const leftward = useKeyboardControls((state) => state.leftward)
    const rightward = useKeyboardControls((state) => state.rightward)
    const jump = useKeyboardControls((state) => state.jump)

    // const restart = usePort((state) => state.restart)
    const title = usePort((state) => state.title)
    const description = usePort((state) => state.description)

    return <div className="interface mr-dafoe-regular">

        {/* <div className="restart">Restart</div> */}
        {/* { title === 'email' && <div className="restart" onClick={ restart }>{ description }</div> } */}
        { (title === 'email' || title === 'linkedin' || title === 'contact') && <div className="restart">{ description }</div> }

        {/* Controls */}
        <div className="controls">
            <div className="raw">
                <div className={ `key ${ forward ? 'active' : '' }` }>W</div>
            </div>
            <div className="raw">
                <div className={ `key ${ leftward ? 'active' : '' }` }>A</div>
                <div className={ `key ${ backward ? 'active' : '' }` }>S</div>
                <div className={ `key ${ rightward ? 'active' : '' }` }>D</div>
            </div>
            <div className="raw">
                <div className={ `key large ${ jump ? 'active' : '' }` }>SPACE</div>
            </div>
        </div>
    </div>
}