import { useFrame } from '@react-three/fiber'
import { SpotLight } from '@react-three/drei'
import { useRef } from 'react'

export default function FrameSpotLight()
{
    const spotlightRef = useRef();
    const spotlightRef2 = useRef();

    useFrame(() => {
        if (spotlightRef.current) {
            // Set rotation to make the spotlight horizontal
            spotlightRef.current.target.position.set(0, - 360, 0); // Adjust the angles as needed
        }

        if (spotlightRef2.current) {
            // Set rotation to make the spotlight horizontal
            spotlightRef2.current.target.position.set(0, - 360, 0); // Adjust the angles as needed
        }
    });

    return <>
    <group position-x={ - 0.5 }>
        <SpotLight
            ref={ spotlightRef }
            castShadow
            distance={ 4 }
            angle={0.90}
            attenuation={ 4 }
            opacity={ 5 }
            anglePower={ 2 }
            position={ [ - 2, 3.8, - 9.95 ] } 
            color={ 'white' }
        />

        <SpotLight
            ref={ spotlightRef2 }
            castShadow
            distance={ 4 }
            angle={0.90}
            attenuation={ 4 }
            opacity={ 5 }
            anglePower={ 2 }
            position={ [ 3, 3.8, - 9.95 ] } 
            color={ 'white' }
        />
    </group>
    </>
}